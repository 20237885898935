
@font-face {
  font-family: "SwissReSans";
  src: url("SwissReSans.eot"); /* IE9 */
  src: url("SwissReSans.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("SwissReSans.woff2") format("woff2"), /* chrome、firefox、opera、Safari, Android, iOS */
  url("SwissReSans.woff") format("woff"), /* chrome、firefox */
  url("SwissReSans.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */
  url("SwissReSans.svg#uxfonteditor") format("svg"); /* iOS 4.1- */
}
@font-face {
  font-family: "SwissReSans Light";
  src: url("SwissReSans Light.eot"); /* IE9 */
  src: url("SwissReSans Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("SwissReSans Light.woff2") format("woff2"), /* chrome、firefox、opera、Safari, Android, iOS */
  url("SwissReSans Light.woff") format("woff"), /* chrome、firefox */
  url("SwissReSans Light.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */
  url("SwissReSans Light.svg#uxfonteditor") format("svg"); /* iOS 4.1- */
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-form-inline .ant-form-item {
  /* margin-bottom: 10px; */
}

.ant-btn {
  box-shadow: none !important;
}

.ant-tabs-tab{
  font-size: 14px;
  color: #000;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 14px;
  color: #000;
}

td.ant-table-column-sort{
  background: transparent;
}